.matter {
    width: 100%;
    height: 100vh;
    position: relative;
}

.matter .container{
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.matter .content h4{
    color: #fff;
}

.matter .aman-agrawal{
    color: white;
    font-size: 4rem;
    font-weight: 900;
}
.matter .content .h1{
    text-shadow: 2px 2px 2px rgba(0,0,0,0.6);
    color: #fff;
    max-width: 660px;
    /* background-color: #000; */
}
.pain{
    position: relative;
    display: flex;
    justify-content: flex-end;
}
.manisha-content{
    position: absolute;
    right: -16%;
    top: -54%;
    width: 48%;
}

.manisha-pandey{
    text-shadow: 2px 2px 2px rgba(0,0,0,0.6);
    color: black !important;
    max-width: 660px;
    font-size: 2.3rem;
    

}
@media screen and (max-width: 992px) {
    .matter .container {
        top: 78%;
    }
}