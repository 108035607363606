.about_btn {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  align-items: flex-end;
}

.about_btn a {
  display: block;
  width: 350px;
  height: 50px;
  line-height: 40px;
  font-size: 22px;
  text-decoration: none;
  color: #333;
  border: 2px solid #333;
  letter-spacing: .5px;
  position: relative;
  transition: all 0.35s;
  margin: 15px 0;
  font-family: "Poppins", sans-serif;
  border-radius: 7px;
  padding: 6px
}

.about_btn a:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #307e03;
  transition: all 0.35s;
  z-index: -1;
}

.about_btn a:hover  {
  color: #fff;
  transition: 0.5s ease;
  z-index: 999;
}

.about_btn a:hover:after {
  width: 100%;
  border: none;
}

.about_btn .active {
  background-color: #307e03;
  color: #fff;
  cursor: not-allowed;
  pointer-events: none;
}


@media (max-width: 768px) {
  .about_btn {
    align-items: center;
  }
}