.blogcontent {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #fffd8b;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blogcontent h1 {
  color: #f7320f;
  font-size: 100px;
  text-transform: uppercase !important;
  font-family: "united_sans_condheavy";
  width: 80%;
  margin: 0 auto;
}
.firstblog .blogdetails{
    padding: 40px 0;
}
.firstblog .content h1{
    color: #000;
    font-size: 50px;
    text-transform: uppercase !important;
    font-family: "united_sans_condheavy";
    font-style: italic;
}
.firstblog .blogdetails p{
    font-family: "Poppins", sans-serif;
    font-size: 22px;
}
.date{
  color: #f7320f;
  display: flex;
  justify-content: flex-end;
}

.blog-img{
  width: 100%;
  height: 100%;
}

.blog-img img{
  width: 100%;
    height: 100%;
}
@media screen and (max-width: 768px) {
  .blogcontent h1 {
    font-size: 60px;
  }
}

@media screen and (max-width: 400px) {
    .blogcontent h1 {
      font-size: 50px;
    }
  }
  @media screen and (max-width: 320px) {
    .blogcontent h1 {
      font-size: 40px;
      margin-top: 100px;
    }
  }