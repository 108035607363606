.firstsection {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url(../images/why.jpg); */
  background-color: #e19404;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.firstsection .square {
  border: 7px solid #307e03;
}

.homelogo {
  width: 140px;
  margin: 25px 0 0 25px;
  transform: translate(10px, -293px);
}

.homelogo img {
  width: 100%;
  height: 100%;
}

.secsection {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #005404; */
  background-image: url(../images/square1.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.secsection .square {
  /* need to put some background image here */
  /* border: 7px solid #e19404; */
}

.thirdsection {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../images/banner11.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.thirdsection .square {
  /* background-image: url(../images/banner11.jpg); */
  background-size: 150% auto;
  /* Adjust the values as needed */
  background-repeat: no-repeat;
  background-position: center center;
}

.most-prominent-section {
  display: flex;
  align-items: center;
  height: 100%;
}

.thirdsection .most-prominent-section{
  height: 100vh;

}
.thirdsection>p {
  color: white;
}

.most-prominent-text {
  width: 50%;
  color: white;
}

.most-prominent-text>h2 {
  font-size: 100px;
  font-family: "united_sans_condheavy";
  text-transform: uppercase;
}

.prahlad-kakar {
  width: 50%;
  height: 100%;
  background-image: url(../images/Prahlad\ Kakkar\ Image.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.prahlad-kakar>img {
  width: 103%;
  height: 103%;
  border-radius: 10px;
}

.foursection {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #8b001e; */
  background-image: url(../images/square4.jpg);

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.foursection .square {
  /* need to put some background image here */
  /* border: 7px solid #e19404; */
}

.fivesection {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #309b7b; */
  background-image: url(../images/banner8.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.fivesection .square {
  /* need to put some background image here */
  /* border: 7px solid #e19404; */
}

.sixsection {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #39742a; */
  background-color: #307e03;
  /* background-image: url(../images/square2.jpg); */

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.sixsection .square {
  /* need to put some background image here */
  border: 7px solid #e19404;
}

.landingmain section {
  height: 100vh;
  width: 100%;
  position: fixed;
  opacity: 0;
  transition: opacity 0.7s ease-in-out;
}

.landingmain section.active {
  opacity: 1;
}

.landingmain section h1 {
  font-size: 120px;
  font-family: "united_sans_condheavy";
  text-transform: uppercase;
  color: #f5f5f5;
  z-index: 1;
  text-align: center;
  position: absolute;
  width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.landingmain section p {
  font-size: 22px;
  position: absolute;
  width: 70%;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  text-align: center;
}

.homecontent {
  max-width: 90%;
  margin: 30px auto;
  text-align: center;
  padding-top: 200px;
}

.homecontent p {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  color: #f5f5f5;
  margin-top: 20px;
}

.square {
  position: fixed;
  z-index: -1;
  width: 48vh;
  height: 48vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 767px) {
  .landingmain section h1 {
    font-size: 65px;
  }

  .square {
    width: 65vw;
    height: 65vw;
  }
}




/* footer  */
.landingfooter {
  height: 750vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #e19404;
}

.footer {
  padding: 40px 0 15px;
  background-color: #e19404;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  z-index: 999;
}

.footercol {
  justify-content: space-around;
  width: 100%;
  display: flex;
}

.footer img {
  width: 150px;
  height: auto;
  margin-left: 20px;
}

.left_footer h4 {
  position: relative;
  padding-bottom: 1rem;
  color: #444444;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 2rem;
  margin-left: 3px;
}

.left_footer {
  border-right: 1px solid #fff;
  padding: 40px 0 40px 20px;
  display: flex;
}

/* .right_footer {
  padding: 40px 0px;
  display: flex;
  justify-content: center;
} */

.right_footer .footercontent h5 {
  margin-bottom: 0.5rem;
  color: #444444;
  font-family: "Poppins", sans-serif;
}

.right_footer .footercontent p,
a {
  color: #444444;
  text-decoration: none;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

/* .footercontent .footeremail {
  display: flex;
  flex-wrap: wrap;
} */

.right_footer .footercontent a:hover {
  color: #444444;
}

.footer p {
  margin-bottom: 0px;
}

.footer ul {
  display: flex;
  /* justify-content: center; */
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  margin: 16px 0 0;
}

.footer ul li a {
  text-decoration: none;
  padding: 10px 24px;
  font-size: 19px;
  color: #444444;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.footer ul li a:hover {
  scale: 1.2;
}

.footer hr {
  color: #e6e3e3;
  border: 1px solid #e6e3e3;
}

.footer .social_link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 32px;
  margin-top: 20px;
}

.footer .footertext p {
  color: #444444;
  font-family: "Poppins", sans-serif;
}

.footer .footertext a {
  color: #444444;
  font-family: "Poppins", sans-serif;
}

@media screen and (max-width: 992px) {
  .homelogo {
    transform: translate(10px, -250px);
  }

  .left_footer {
    padding: 40px 0px;
  }

  .footer ul {
    flex-wrap: wrap;
  }

  /* .footer ul li a {
    padding:;
  } */

  .footer .social_link {
    display: flex;
    flex-wrap: wrap;
  }

  .footer .footertext p {
    text-align: center;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .left_footer {
    padding: 25px 0;
    display: flex;
    flex-wrap: wrap;
  }

  .homelogo {
    transform: translate(10px, -250px);
  }

  .right_footer .footercontent p,
  a {
    font-size: 15px;
  }
  .most-prominent-section {
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
  .most-prominent-text {
    padding: 91px 0 0 0;
    width: 100%;
}
.most-prominent-text>h2 {
  font-size: 45px;
}
  .prahlad-kakar {
    width: 100%;
}
}

@media screen and (max-width: 425px) {
  .landingmain section h1 {
    font-size: 40px;
  }

  .landingmain section p {
    font-size: 18px;
  }

  .square {
    width: 65vw;
    height: 65vw;
  }
  .homelogo {
    transform: translate(10px, -450px);
    top: 0;
    left: 0;
    position: relative;
  }

  .most-prominent-section {
    align-items: center;
    flex-direction: column;
    height: 100%;
  }

  .prahlad-kakar {
    width: 109%;
  }

  .most-prominent-text {
    padding: 91px 0 0 0;
    width: 90%;
  }

  .most-prominent-text>h2 {
    font-size: 39px;
  }
}


@media screen and (max-width: 325px) {
  .landingmain section h1 {
    font-size: 35px;
  }

  .homecontent {
    max-width: 80%;
    margin: 0px auto;
    text-align: center;
    padding-top: 100px;
  }

  .landingmain section p {
    font-size: 15px;

  }

  .square {
    width: 65vw;
    height: 65vw;
  }

  .right_footer {
    justify-content: flex-start;
  }

  .most-prominent-section {
    align-items: center;
    flex-direction: column;
    height: 100%;
  }

  .prahlad-kakar {
    width: 100%;
  }

  .most-prominent-text {
    width: 90%;
  }

  .most-prominent-text>h2 {
    font-size: 45px;
  }
}