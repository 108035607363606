 header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: transparent;
  padding: 0 40px;
  top: 30px;
  left: 0;
  z-index: 1;
  width: 100%;
  z-index: 5;
  transition: 0.5s ease-in-out;
}

header.pageview{
  /* background-color: white; */
  background: transparent;
}

header.pageview a{
  color: black;
}

.patch{
  position: fixed;
  top: 0;
  width: 100%;
  /* height: 30px; */
  background-color: white;
  z-index: 1;
}

header .logo {
  width: 10%;
  height: inherit;
  transition: 0.5s ease-in-out;
}

header .logo img {
  max-width:100%;
  max-height:100%;
}

.menu {
  display: block;
  position: absolute;
  top: -14;
  right: 0;
  width: 60%;
  height: 8vh;
  padding-top: 20px;
  padding-bottom: 55px;
  padding-right: 5%;
  background-color: #1b1b1b00;
  transition: 0.5s ease-in-out;
  /* overflow-y: scroll; */
}

.menu ul {
  margin-top: 85px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menu li {
  top: 30px;
}

.menu.open li a {
  font-weight: bold;
  text-transform: uppercase;
  padding: 15px 15px 15px 30px;

}

.menu.open span {
  color: #fff;
}

.menu ul {
  gap: 20px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu li {
  margin: 10px 0;
}

.menu a {
  display: block;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
}

.menu-icon {
  display: none;
}

header.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: 0.5s ease-in-out;
}
header.navbarcss{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}
.header.navbarcss{
  padding: 10px 20px;
}
.header.fixed .container {
  padding: 10px 20px;
}

header.navbarcss span{
  background: #ae0000;
}
header.fixed span {
  background: #ae0000;
}
header.navbarcss {
  padding: 10px 40px;
}
header.fixed {
  padding: 10px 40px;
  background-color: #fff;
}

header.navbarcss .logo{
  margin-left: -25px;
  transition: 0.5s ease-in-out;
}
header.fixed .logo{
  margin-left: -25px;
  transition: 0.5s ease-in-out;
}

header.navbarcss .menu{
  padding-right: 2%;
  transition: 0.5s ease-in-out;
}
header.fixed .menu{
  padding-right: 2%;
  transition: 0.5s ease-in-out;
}

.page{
  background-color: black;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page a{
  color: white !important;
  padding-left: 5px;
  padding-right: 5px;
}


@media screen and (max-width: 768px) {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    background-color: transparent;
    padding: 0 40px;
    top: 30px;
    left: 0;
    z-index: 1;
    width: 100%;
    z-index: 5;
    transition: 0.5s ease-in-out;
  }

  header.pageview{
    background-color: initial;
    color: initial;
  }
  header.pageview a{
    color: #fff;
  }

  header.navbarcss .logo{
    margin-left: -25px;
    transition: 0.5s ease-in-out;
  }

  header.fixed .logo{
    margin-left: -25px;
    transition: 0.5s ease-in-out;
  }
  
  header .logo {
    width: 140px;
    padding: 8px;
    transition: 0.5s ease-in-out;
  }
  header .logo img {
    max-width:100%;
    max-height:100%;
  }
  
  .menu {
    display: none;
  }
  
  .menu.open {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    width: 18%;
    height: 100vh;
    background-color: #1b1b1b;
    transition: 2s ease-in-out;
    overflow-y: scroll;
  }
  
  .menu.open ul {
    display: block;
    margin-top: 85px;
  }
  
  .menu.open li {
    top: 30px;
  }
  
  .menu.open li a {
    font-weight: bold;
    text-transform: uppercase;
    padding: 15px 15px 15px 30px;
  
  }
  
  .menu.open span {
    color: #fff;
  }
  
  .menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .menu li {
    margin: 10px 0;
  }
  
  .menu a {
    display: block;
    color: #fff;
    text-decoration: none;
    font-size: 18px;
  }
  
  .menu-icon {
    display: block;
    width: 30px;
    height: 20px;
    position: relative;
    cursor: pointer;
    color: #fff;
  }
  
  .menu-icon span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #fff;
    border-radius: 4px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: all 0.25s ease-out;
  }
  
  .menu-icon span:nth-child(1) {
    top: 0;
  }
  
  .menu-icon span:nth-child(2),
  .menu-icon span:nth-child(3) {
    top: 8px;
  }
  
  .menu-icon span:nth-child(4) {
    top: 16px;
  }
  
  .menu-icon.open span:nth-child(1) {
    top: 8px;
    transform: rotate(90deg);
  }
  
  .menu-icon.open span:nth-child(2) {
    transform: rotate(90deg);
    left: 12px;
  }
  
  .menu-icon.open span:nth-child(3) {
    transform: rotate(90deg);
    left: -12px;
  }
  
  .menu-icon.open span:nth-child(4) {
    top: 8px;
    width: 0%;
    left: 50%;
  }
  header.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    transition: 0.5s ease-in-out;
  }
  header.navbarcss{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    transition: 0.5s ease-in-out;
  }
  .header.navbarcss{
    padding: 10px 20px;
    transition: 0.5s ease-in-out;
  }
  .header.fixed .container {
    padding: 10px 20px;
  }
  
  header.navbarcss span{
    background: #ae0000;
  }
  header.fixed span {
    background: #ae0000;
  }
  header.navbarcss {
    padding: 10px 40px;
  }
  header.fixed {
    padding: 10px 40px;
    background-color: white;
  }
  .middle p {
    display: none;
  }
  .page{
    background-color: initial;
    height: initial;
    display: initial;
    justify-content: initial;
    align-items: initial;
  }
  .patch{
    display: none;
  }
}
@media screen and (max-width: 520px) {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    background-color: transparent;
    padding: 0 40px;
    top: 30px;
    left: 0;
    z-index: 1;
    width: 100%;
    z-index: 5;
    transition: 0.5s ease-in-out;
  }

  header.pageview{
    background-color: initial;
    color: initial;
  }
  header.pageview a{
    color: #fff;
  }
  
  header.navbarcss .logo{
    margin-left: -25px;
    transition: 0.5s ease-in-out;
  }
  header.fixed .logo{
    margin-left: -25px;
    transition: 0.5s ease-in-out;
  }
  header .logo {
    width: 140px;
    padding: 8px;
    transition: 0.5s ease-in-out;
  }
  header .logo img {
    max-width:100%;
    max-height:100%;
  }
  
  .menu {
    display: none;
  }
  
  .menu.open {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    width: 18%;
    height: 100vh;
    background-color: #1b1b1b;
    transition: 2s ease-in-out;
    overflow-y: scroll;
  }
  
  .menu.open ul {
    display: block;
    margin-top: 85px;
  }
  
  .menu.open li {
    top: 30px;
  }
  
  .menu.open li a {
    font-weight: bold;
    text-transform: uppercase;
    padding: 15px 15px 15px 30px;
  
  }
  
  .menu.open span {
    color: #fff;
  }
  
  .menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .menu li {
    margin: 10px 0;
  }
  
  .menu a {
    display: block;
    color: #fff;
    text-decoration: none;
    font-size: 18px;
  }
  
  .menu-icon {
    display: block;
    width: 30px;
    height: 20px;
    position: relative;
    cursor: pointer;
    color: #fff;
  }
  
  .menu-icon span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #fff;
    border-radius: 4px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: all 0.25s ease-out;
  }
  
  .menu-icon span:nth-child(1) {
    top: 0;
  }
  
  .menu-icon span:nth-child(2),
  .menu-icon span:nth-child(3) {
    top: 8px;
  }
  
  .menu-icon span:nth-child(4) {
    top: 16px;
  }
  
  .menu-icon.open span:nth-child(1) {
    top: 8px;
    transform: rotate(90deg);
  }
  
  .menu-icon.open span:nth-child(2) {
    transform: rotate(90deg);
    left: 12px;
  }
  
  .menu-icon.open span:nth-child(3) {
    transform: rotate(90deg);
    left: -12px;
  }
  
  .menu-icon.open span:nth-child(4) {
    top: 8px;
    width: 0%;
    left: 50%;
  }
  header.fixed {
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  }
  header.navbarcss{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  }
  .header.navbarcss{
    padding: 10px 20px;
  }
  .header.fixed .container {
    padding: 10px 20px;
  }
  
  header.navbarcss span{
    background: #ae0000;
  }
  header.fixed span {
    background: #ae0000;
  }
  header.navbarcss {
    padding: 10px 40px;
  }
  header.fixed {
    padding: 10px 40px;
  }
  .menu.open {
    overflow-y: scroll;
    height: 100vh;
    width: 40%;
  }
  header.navbarcss{
    z-index: 1;
  }
  .page{
    background-color: initial;
    height: initial;
    display: initial;
    justify-content: initial;
    align-items: initial;
  }
  .patch{
    display: none;
  }
}
