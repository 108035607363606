.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 40px 0;
}
.blogcard {
  display: flex;
  flex-wrap: wrap;
  width: 33%;
}
.card {
  margin: 10px auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 0;
}
.card-body h3 {
  font-size: 25px;
  font-family: "united_sans_condheavy";
}
/* .card-body>p{
  font-family: "united_sans_condmedium";
} */

.blog-card-body{
  min-height: 40vh;
}
.card button {
  padding: 8px 12px;
  border: 2.5px solid #195219;
  background: transparent;
  font-size: 12px;
  text-transform: uppercase;
  color: #195219;
  font-weight: 600;
  transition: 0.5s all;
}
.card img {
  /* width: 100%;
  height: 100%; */
  width: 100%;
  aspect-ratio: 3/3;
  /* object-fit: cover; */
}
.btndate {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.btndate p {
  margin: 0;
  font-size: 15px;

  font-family: "Poppins", sans-serif;
  /* font-family: "united_sans_condmedium"; */
}
.card button:hover {
  background: #195219;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .card-list {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
  }
  .blogcard {
    width: 100%;
  }
}
