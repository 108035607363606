.teambanner {
  height: 110vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 89%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 89%, 0% 100%);
  position: relative;
}
.teamabout {
  padding: 40px 0;
}
.teamabout h3 {
  font-family: "united_sans_condheavy";
  font-size: 45px;
}

.teamabout .teamaboutleft p {
  font-size: 20px;
  color: #161616;

  font-family: "Poppins", sans-serif;

}

.teamabout .teamaboutright {
  padding: 0 0 0 20px;
}
.teamabout .teamaboutright p {
  /* font-family: "united_sans_condbold"; */
  font-family: "united_sans_condmedium";

  font-style: italic;
  font-size: 40px;
  line-height: 1.1;
  color: #161616;
}
.teamabout .teamaboutright span {
  font-family: "Poppins", sans-serif;
  color: #161616;
}
.teambanner .content {
  position: absolute;
  top: 40%;
  left: 4%;
  color: white;
  z-index: 1;
}
.teambanner .content h1 {
  color: #fff;
  font-size: 84px;
  text-transform: uppercase !important;
  text-align: left;
  font-family: "united_sans_condheavy";
  margin-bottom: 0;
}
.teambanner .content p {
  font-family: "Lato";
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0;
  padding-bottom: 0;
}
