.servicecontent {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.servicecontent .service-img-1,.servicecontent .service-img-2,.servicecontent .service-img-3,.servicecontent .service-img-4 {
  object-fit: cover;
  height: 400px;
  background-position: center;
  background-size: cover;
  border-radius: 3px
}
.servicecontent .service-img-1{
  background-image: url(../../images/a.jpg);

}
.servicecontent .service-img-2{
  background-image: url(../../images/b.jpg);
  
}.servicecontent .service-img-3{
  background-image: url(../../images/c.jpg);
  
}.servicecontent .service-img-4{
  background-image: url(../../images/d.jpg);
  
}
.servicecontent .service-img img {
  width: 100%;
  height: 100%;
}
.servicecontent h2 {
  font-family: "united_sans_condheavy";
  font-size: 40px;
}
.servicecontent p {
    font-family: "Poppins", sans-serif;
    font-size: 22px;
}
.servicecontent li{
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    padding-left: 5px;
}

.cost-comparison{
  font-family: "united_sans_condheavy";
  font-weight: lighter;
  font-size: 55px;
}


.table{
  background-color: lightgray;
  width: 70%;
  margin: 0 auto;
}

.table tr:hover{
  background-color: #fff;
}


@media screen and (max-width: 992px) {
  .table{
    width: 100%;
   
  }
}

@media screen and (max-width: 768px) {
  .table{
    width: 100%;
   
  }
  .margin-bottom{
    margin-bottom: 3rem;
  }
  .margin-bottom:nth-last-child(1){
    border: 2px solid gray;
  }
}