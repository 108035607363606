.Teamabout {
    padding: 100px 0;
    background-color: #0f750f;
}

.Teamabout h3 {
    font-size: 40px;
    color: #fff;
    font-family: "united_sans_condheavy";
    margin-bottom: 30px;
}

.Teamabout h6 {
    font-size: 17px;
    color: #fff;
    font-family: "Poppins", sans-serif;
}
.progres{
    width: 90%;
    margin: 0 auto;
}

.progress {
    border: 1px solid #B0B0B0;
    width: 100%;
    height: 50px;
    border-radius: 0 !important;
    border: none;
    box-shadow: 0 0 3px #B0B0;
    background-color: #333;
    backdrop-filter: blur(2px) brightness(101%);
    clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
}

.bar {
    width: 100%;
    background: #e19404;
    box-shadow: inset 0px 12px 1.7px #FFFFFF33;
    color: white;
    clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);

}

.bar p {
    display: inline-block;
    text-align: right;
    align-items: start;
    margin: 15px !important;
    font-size: 15px;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;

}
