.about-content {
  /* padding: 60px 0; */
  padding: 60px 0 150px 0
}

.about-content h3 {
  font-size: 50px;
  font-family: "united_sans_condheavy";
}

.about_content p {
  font-family: "Poppins", sans-serif;
  /* font-family: "united_sans_condmedium"; */
  font-size: 22px;
}

.value-add-subHeading {
  font-size: 2.2rem;
}

.about_content p a {
  color: #000;
}

.about-content li {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  /* font-family: "united_sans_condmedium"; */

}

.about-content p a {
  font-size: 22px;
  text-decoration: underline;
  color: #000;
}

.about-content p a:hover {
  color: #307e03;
}

.aboutimg {
  object-fit: cover;
  height: 400px;
  background-image: url(../../images/auto11.jpg);
  background-position: center;
  background-size: cover;
}

.aboutimg img {
  width: 100%;
  height: 100%;
}

.sub-heading-img {
  justify-content: center;
  display: flex;
  /* height: 60%; */
  /* width: 100vh;
  height: 85vh; */
  /* width: 800px;
  height: 89vh */
}
.sub-heading-img>img{
  width: 100%;
}

@media (max-width: 600px) {
  .about_content h1 {
    font-size: 50px;
  }
}

@media (max-width: 425px) {
  .sub-heading-img {
    justify-content: center;
    display: flex;
    width: 332px;
    border-radius: 5px;
    /* height: 29vh; */
  }
}