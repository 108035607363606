.zoom-in-zoom-out {
  position: fixed;
  width: 100vw;
  height: 100vw;
  z-index: 1001;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.zoom {
  width: 0px;
  height: 0px;
  background: #307e03;
  animation: zoom-in-zoom-out 1.5s ease-out infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
 .zoom img {
  width: 120px;
  height: auto;
  position: absolute;
  animation: image-zoom 1s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1, 1) rotate(45deg);
  }
  30% {
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.5, 1.5) rotate(45deg);
  }
  80% {
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.5, 1.5) rotate(45deg);
  }
  100% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1, 1) rotate(45deg);
  }
}
@keyframes image-zoom{
  0% {
    transform: scale(1) rotate(-45deg);
   
  }
  50% {
    transform: scale(1) rotate(-45deg);
   
  }
  80% {
    transform: scale(1) rotate(-45deg);
   
  }
 
  100% {
    transform: scale(0) rotate(-45deg);
  }
}

@media screen and (max-width: 768px) {
  .zoom-in-zoom-out {
    position: fixed;
    width: 100vh;
    height: 100vh;
    z-index: 1001;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
}
@media screen and (max-width: 520px) {
  .zoom-in-zoom-out {
    position: fixed;
    width: 100vh;
    height: 100vh;
    z-index: 1001;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
}
