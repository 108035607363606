.careercontent {
  padding: 80px 0;
}
.careercontent h1 {
  font-weight: bold;
  font-size: 80px;

  font-family: "united_sans_condheavy";
}

.careercontent p {
  font-family: "Poppins", sans-serif;
  /* font-family: "united_sans_condmedium"; */
  font-size: 22px;
}
