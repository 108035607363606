.socialmedia {
    padding: 150px;
    width: 100%;
    /* height: 85vh; */
    background-color: #35911e;
    position: absolute;
    z-index: 1;
    clip-path: polygon(0% 0%, 100% 15%, 100% 50%, 100% 81%, 0% 100%);
}

.socialmedia h3 {
    font-size: 40px;
    font-family: "united_sans_condheavy";
    color: #ebe6e6;
    margin-bottom: 40px;
}

.socialmedia .media {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    /* padding: 50px 0 0 40px; */
    
    padding: 30px 0;
}

.socialmedia .media a {
    color: #ebe6e6;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    
}

@media screen and (max-width: 992px) {
    .socialmedia {
        padding: 100px 10px;
    }
}