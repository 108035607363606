.contactform {
  padding: 0 0 50px 0;
}

.address-div {
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.address-section {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  /* gap: 15px; */
}

.lavel {
  display: flex;
  /* justify-content: space-between; */
  /* width: 30%; */
}

.value {
  width: 70%;
}

.value p {
  font-size: 22px;
}

.address-lavel {
  color: #4d4a4a;
  font-family: united_sans_condheavy;
  font-size: 25px;
  /* padding-bottom: 20px; */
}

.contact-adrress p {
  font-family: Poppins, sans-serif;
  font-size: 22px;
}

.contactform h2 {
  font-size: 50px;
  color: #4d4a4a;
  font-family: "united_sans_condheavy";
  padding-bottom: 20px;
}

.checkbox-wrapper label {
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  font-size: 17px;
}

.contactform textarea {
  width: 100%;
  min-height: 160px;
  resize: none;
  outline: none;
  border: none;
  z-index: 9;
  width: calc(100% - 4px);
  border: none;
  background-color: transparent;
  height: calc(100% - 4px);
  z-index: 9;
  padding: 16px 20px;
  font-size: 16px;
}

.m-fieldset-field textarea:focus~.m-fieldset-field__label,
.m-fieldset-field textarea:valid~.m-fieldset-field__label {
  top: -10px;
  font-size: 12px;
  left: 0;
}

.m-fieldset-field textarea:focus~.m-fieldset-bg,
.m-fieldset-field textarea:valid~.m-fieldset-bg {
  clip-path: none;
}

.m-fieldset-field textarea:focus~.m-fieldset-bg::after,
.m-fieldset-field textarea:valid~.m-fieldset-bg::after {
  clip-path: none;
}

.input-lable {
  opacity: 0;
  transform: translateY(25%);
  transition: 0.25s ease;
  transition-property: opacity, transform;
}

.m-fieldset-field {
  color: #161616;
  display: flex;
  position: relative;
  margin: 20px 0;
}

.selectedFile {
  position: absolute;
  top: 32%;
  left: 45%;
}

.m-fieldset-field input {
  width: calc(100% - 4px);
  border: none;
  background-color: transparent;
  height: calc(100% - 4px);
  z-index: 9;
  padding: 16px 20px;
  font-size: 22px;
}

.m-fieldset-field input:focus {
  box-shadow: none;
  outline: none;

}

.m-fieldset-field.inputmessage {
  height: 200px;
}

.m-fieldset-field input:focus~.m-fieldset-field__label,
.m-fieldset-field input:valid~.m-fieldset-field__label {
  top: -10px;
  font-size: 12px;
  left: 0;
}

.m-fieldset-field input:focus~.m-fieldset-bg,
.m-fieldset-field input:valid~.m-fieldset-bg {
  clip-path: none;
}

.m-fieldset-field input:focus~.m-fieldset-bg::after,
.m-fieldset-field input:valid~.m-fieldset-bg::after {
  clip-path: none;
}

.m-fieldset-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: #fff;
  clip-path: polygon(16px 0,
      0 16px,
      0 calc(100% - 16px),
      16px 100%,
      calc(100% - 16px) 100%,
      100% calc(100% - 16px),
      100% 16px,
      calc(100% - 16px) 0);
  transition: all 0.5s;
  color: rgba(22, 22, 22, 0.25);
}

.m-fieldset-bg::before {
  content: "";
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: currentColor;
  opacity: 0.66;
  transition: all 0.5s;
  transition-property: color;
}

.m-fieldset-bg::after {
  content: "";
  background: #fff;
  bottom: 2px;
  left: 2px;
  position: absolute;
  right: 2px;
  top: 2px;
  transition: all 0.5s;
  clip-path: polygon(15px 0,
      0 15px,
      0 calc(100% - 15px),
      15px 100%,
      calc(100% - 15px) 100%,
      100% calc(100% - 15px),
      100% 15px,
      calc(100% - 15px) 0);
}

.m-fieldset-field__label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  font-size: 16px;
  text-transform: uppercase;
  transition: 0.2s linear;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #727171;
}

.m-fieldset-field__label.textarea {
  top: 25px;
}

.formbtn,
.formbtnHvover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* checkbox */
input[type="checkbox"]:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  outline: none;
}

.style3 {
  height: 100%;
  width: 100%;
  transition: 0.3s;
}

.style3:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: rgba(22, 22, 22, 0.25);
  top: 7px;
  left: 9px;
  transition: all 0.3s;
  transform: rotate(45deg);
  transition: 375ms ease;
}

.style3:checked::before {
  transform: rotate(135deg);
  left: 32px;
  background-color: #ffca0f;
}

.m-fieldset-field2 {
  color: #161616;
  display: flex;
  width: 60px;
  height: 34px;
  position: relative;

}

.m-fieldset-field2 input {
  width: calc(100% - 4px);
  border: none;
  background-color: transparent;
  height: calc(100% - 4px);
  z-index: 9;
  padding: 13px 20px;
  font-size: 16px;

}

.m-fieldset-field2 input:focus {
  box-shadow: none;
  outline: none;
}

.m-fieldset-bg2 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: #fff;
  clip-path: polygon(16px 0,
      0 16px,
      0 calc(100% - 16px),
      16px 100%,
      calc(100% - 16px) 100%,
      100% calc(100% - 16px),
      100% 16px,
      calc(100% - 16px) 0);
  transition: all 0.5s;
  color: rgba(22, 22, 22, 0.25);
}

.m-fieldset-bg2::before {
  content: "";
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: currentColor;
  opacity: 0.66;
  transition: all 0.5s;
  transition-property: color;
}

.m-fieldset-bg2::after {
  content: "";
  background: #fff;
  bottom: 2px;
  left: 2px;
  position: absolute;
  right: 2px;
  top: 2px;
  transition: all 0.5s;
  clip-path: polygon(15px 0,
      0 15px,
      0 calc(100% - 15px),
      15px 100%,
      calc(100% - 15px) 100%,
      100% calc(100% - 15px),
      100% 15px,
      calc(100% - 15px) 0);
}

.checkbox-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  padding-bottom: 20px;
}

.checkbox-wrapper label {
  margin-left: 10px;
  cursor: pointer;
}

/* button  */
.formbtn button {
  padding: 16px 35px;
  border: 2.5px solid #e19404;
  background: transparent;
  font-size: 20px;
  text-transform: uppercase;
  color: #e19404;
  font-weight: 600;
  transition: 0.5s all;
  cursor: not-allowed;
}

.formbtnHvover button {
  padding: 16px 35px;
  border: 2.5px solid #e19404;
  background: transparent;
  font-size: 20px;
  text-transform: uppercase;
  color: #e19404;
  font-weight: 600;
  transition: 0.5s all;
}

.formbtnHvover button:hover {
  background: #e19404;
  color: #fff;
}


.error input {
  border: 2px solid red;
  clip-path: none;
  color: #161616;
  display: flex;
  position: relative;
  margin: 20px 0;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  z-index: 9;
  padding: 13px 20px;
  font-size: 16px;
}

.error input:focus~.m-fieldset-field__label,
.error input:valid~.m-fieldset-field__label {
  top: -10px;
  font-size: 12px;
  left: 0;
}

.redborder {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: #fff;
  transition: all 0.5s;
  color: rgba(22, 22, 22, 0.25);
  border: 2px solid red;
}

.bordernone {
  clip-path: polygon(16px 0,
      0 16px,
      0 calc(100% - 16px),
      16px 100%,
      calc(100% - 16px) 100%,
      100% calc(100% - 16px),
      100% 16px,
      calc(100% - 16px) 0);
}

.recaptch-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border: 2px solid balck;
}

.recaptch-container iframe div {
  width: 150%;
}

.rc-anchor-logo-text {
  font-size: 20px;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: 'Upload File';
  display: inline-block;
  background: linear-gradient(to top, #f9f9f9, #e3e3e3);
  border: 1px solid rgba(22, 22, 22, 0.2);
  border-radius: 3px;
  padding: 4px 18px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 16px;
  color: rgb(122, 122, 122);

}

.m-fieldset-field .custom-file-input {
  -webkit-appearance: none;
  font-size: 0;
}


/* --------------------------------------career form--------------------------------- */
.career-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.career-file-input::before {
  content: 'Upload CV';
  display: inline-block;
  background: linear-gradient(to top, #f9f9f9, #e3e3e3);
  border: 1px solid rgba(22, 22, 22, 0.2);
  border-radius: 3px;
  padding: 4px 18px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 16px;
  color: rgb(122, 122, 122);

}

.m-fieldset-field .career-file-input {
  -webkit-appearance: none;
  font-size: 0;
}


@media (max-width: 768px) {
  
 .value{
  width: 95%;
 }
 .value p{
  font-size: 20px;
 }
 .address-div {
  padding: 25px;
}
 
  }

  @media (max-width: 425px) {
  
    .value p{
     font-size: 18px;
    }
    .address-div {
      padding: 20px;
  }
    
     }