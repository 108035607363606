.soonlogo {
  width: 300px;

  margin: 0 auto;
  padding-bottom: 20px;
}

.soonlogo img {
  width: 100%;
  height: 100%;
}

.home_hero {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e19404;
  position: relative;
}

.comminsoonontent {
  max-width: 80%;
  margin: 0 auto;
}

.home_hero .logo {
  width: 200px;
  margin: 0 auto;
}

.home_hero .logo img {
  width: 100%;
  height: 100%;
}

.home_hero h1 {
  font-size: 96px;
  font-family: "united_sans_condheavy";
  letter-spacing: 6px;
  color: #444444;

}

.home_hero h3 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 40px;
  letter-spacing: 2px;
  color: #444444;
  font-family: "united_sans_condheavy";
}

.home_hero .phone {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.home_hero .address {
  margin-top: 10px;
}

.home_hero .address h4 {
  color: #444444;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 2px;
  font-family: "united_sans_condheavy";
}

.home_hero .phone p {
  margin: 0 14px;
  font-size: 30px;
  color: #444444;
  font-family: "united_sans_condheavy";
  letter-spacing: 2px;

}

.home_hero .phone a:hover {
  color: #444444;
}

@media screen and (max-width: 520px) {

  .home_hero .phone {
    flex-direction: column;
  }

  .home_hero h1 {
    padding: 0;
    margin: 0;
    font-size: 80px;
  }

  .comminsoonontent {
    padding-top: 70px;
  }
}

@media screen and (max-width: 400px) {
  .comminsoonontent {
    padding-top: 70px;
  }

  .home_hero h1 {
    font-size: 60px;
  }
}

@media screen and (max-width: 325px) {
  .home_hero h1 {
    font-size: 50px;
  }

  .comminsoonontent {
    padding-top: 100px;
  }

  .home_hero .address h4 {
    font-size: 20px;
  }

  .home_hero h3 {
    font-size: 30px;
    margin: 0;
    padding: 0;
  }
}