.aboutbanner {
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  transition: background-size 0.5s ease;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 89%, 0% 100%);
  clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);
  position: relative;
}
.aboutbanner .logo {
  width: 140px;
  margin: 25px 0 0 25px;
}
.aboutbanner .logo{
  transform: translate(10px -15px);
}
.aboutbanner .logo img{
  width: 100%;
  height: 100%;
}

.aboutbanner .content {
  position: absolute;
  top: 50%;
  left: 4%;
  color: white;
  z-index: 1;
}
.aboutbanner .content h1 {
  color: #fff;
  font-size: 84px;
  text-transform: capitalize !important;
  text-align: left;
  font-family: "united_sans_condheavy";
}

@media (max-width: 768px) {
  .aboutbanner {
    height: 100vh;
  }
}

@media (max-width: 600px) {
  
.aboutbanner .content h1{
  font-size: 50px;
}
}