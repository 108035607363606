.contact-hero {
  position: relative;
  padding: 220px 0 250px;
  background: #dedede;
  background-image: url("https://res.cloudinary.com/dznncrjxh/image/upload/v1693548417/career_1_nhe6zx.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}
.contact-hero .logo{
  transform: translate(3px, -220px);
}
.contact-hero .logo{
  width: 140px;
  margin: 25px 0 0 25px;
}
.contact-hero .logo img{
  width: 100%;
  height: 100%;
}
.contact-hero::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  clip-path: polygon(-12% 100%, 100% 0, 100% 100%, 0% 100%);
  background-color:#ffca0f;
  z-index: 1;
}
.contact-hero::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 78px;
  clip-path: polygon(-9% 100%, 100% 0, 100% 100%, 0% 100%);
  background-color: #fff;
  z-index: 2;
}
.contact-hero h1 {
  color: #fff;
  font-size: 84px;
  font-weight: bold;
  text-transform: capitalize !important;
  text-align: left;
  font-family: "united_sans_condbold";
  font-family: "united_sans_condheavy";
}
.contact-hero p {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
}

.contactherocontent{
  position: absolute;
  top: 50%;
  left: 4%;
  color: white;
  z-index: 1;
}

