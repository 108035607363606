.slidermain {
  display: flex;
  width: 99%;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.slidermain input {
  border: none;
  outline: none;
  padding: 10px;
  margin: 10px;
}

.slidermain .sliderplan {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
}

.slidermain .inputtext {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  /* font-family: "united_sans_condmedium"; */
}

.slidermain .inputtext input {
  text-align: center;
}

.slidermain span {
  font-weight: 900;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  /* font-family: "united_sans_condmedium"; */

}

.slidermain .rangeslider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

/* price calculation */
.price-calculation {
  display: flex;
  width: 99%;
  padding: 0 45px;
}

.price-calculation .price-container {
  display: flex;
  width: 98%;
  justify-content: space-between;
}

.price-calculation .result {
  /* margin: 10px 0; */
  min-width: 45%;
  text-align: center;
  box-shadow: 2px 2px 2px green;
  /* border: 1px solid; */
  border-radius: 10px;
}

.price-calculation .result>p {
  padding: 20px 5px;
  background-color: #039341;
  color: white;
  font-size: 22px;
  border-radius: 10px;
  box-shadow: 2px 3px 6px #000;
  font-weight: 900;

}

.price-calculation .result .price-value {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  padding: 20px;
}

@media screen and (max-width: 992px) {
  .slidermain {
    padding-left: 20px;
    flex-direction: column;
    margin: 0 auto;
  }

  .price-calculation {
    width: 95%;
    padding: 0 0;

  }

  .price-calculation .result>p {
    height: 50%
  }
}

@media screen and (max-width: 430px) {
  .rangeslider {
    padding: 0 10px;
  }
  .price-calculation {
    width: 95%;
  }
  .slidermain {
    padding-left: unset;  
  }
  .css-1m3p9o7 {
    width: 90%!important;
  }

  .slidermain span {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
  }

  .slidermain .sliderplan {
    justify-content: center;
  }
}

@media screen and (max-width: 420px) {

  .css-1m3p9o7 {
    width: 90% !important;
  }
  .slidermain {
    padding-left: unset;  
  }
  .price-calculation {
    width: 95%;
  }
  .slidermain span {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
  }

  .price-calculation .price-container {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 0 auto;
  }

  .slidermain .sliderplan {
    justify-content: center;

  }
}

/* ------------------------------------------------------------------- */