.autotable {
    font-family: "Poppins", sans-serif;
    padding-bottom: 100px;
}
.autotable .cost-comparison{
    text-transform: uppercase;
}

.table-responsive {
    overflow-x: auto;
    margin-bottom: 30px;
}


table {
    border: 1px solid #ccc;
    font-family: "Poppins", sans-serif;
    margin-bottom: 40px;
 
}

table td tr:nth-child(2) {
    display: flex;
    justify-content: flex-end;
}

table td {
    font-size: 22px;
}

.cancelled {
    color: red;
    font-weight: bold;
}


.autotable p {
    font-size: 22px;
  /* font-family: "united_sans_condmedium"; */
  font-family: "Poppins", sans-serif;


}
.table td {
    font-size: 22px;
  /* font-family: "united_sans_condmedium"; */
  font-family: "Poppins", sans-serif;


}

.table tr {
    display: flex;
    align-items: center;
}

.table td[data-label] {
    flex: 1;
    /* display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold; */
}

.table td[data-label]::before {
    content: attr(data-label);
    color: lightgrey;
    font-weight: 100;
    width: 50%;
    display: none;
}

.table tbody tr:nth-child(2) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* justify-content: end; */
}

.therefor{
    font-weight: bold;
}

@media (max-width: 678px) {

    table thead {
        display: none;
    }

    table tr {
        display: flex;
        flex-direction: column;
        border: 3px solid white;
        padding: 1px;
        text-align: center;
    }


    table td[data-label] {
        display: flex;
        font-weight: bold;
        margin: 0 auto;
    }

    table td[data-label]::before {
        content: attr(data-label);
        color: lightgrey;
        font-weight: 100;
        width: 50%;
        display: none;
    }


}
@media (max-width: 575px) {

    table thead {
        display: none;
    }

    table tr {
        display: flex;
        flex-direction: column;
        border: 3px solid white;
        padding: 1px;
        text-align: center;
    }


    table td[data-label] {
        display: flex;
        font-weight: bold;
        margin: 0 auto;
    }

    table td[data-label]::before {
        content: attr(data-label);
        color: lightgrey;
        font-weight: 100;
        width: 50%;
        display: none;
    }


}