.teamcontent {
  padding: 80px 0;
}

.teamcontent h1 {
  font-weight: bold;
  font-family: "united_sans_condheavy";
  font-size: 80px;
  text-transform: uppercase;
}
.leadership{
  text-transform: uppercase;
}
.teamcontent p {
  font-family: "Poppins", sans-serif;
  /* font-family: "united_sans_condmedium"; */
  font-size: 22px;
}

.teamhead {
  /* background-color: #e7e7e5; */
  padding: 20px 0 0;
}
.teamhead span {
  display: block;
  font-size: 24px;
  font-style: italic;
  font-family: "Lato";
}
.teamhead h1 {
  font-size: 45px;
  font-family: "united_sans_condheavy";
}
@media screen and (max-width: 768px) {
  .teamcontent h1 {
    font-size: 40px;
  }
  }
@media screen and (max-width: 420px) {
.teamcontent h1 {
  /* font-size: 50px; */
  font-size: 40px;
}
}