.swiper-container, .swiper-wrapper, .swiper-slide {
	 width: 100%;
	 height: 100%;
}
 .swiper-slide {
	 text-align: center;
	 font-size: 18px;
	 background: #fff;
	 display: flex;
	 justify-content: center;
	 align-items: center;
}
 .swiper-slide:nth-child(1) {
	 background: #26db6e;
}
 .swiper-slide:nth-child(2) {
	 background: #000;
}
 .swiper-slide:nth-child(3) {
	 background: #893743;
}
 .swiper-slide:nth-child(4) {
	 background: #f4acb7;
}
 