.cofounder .card {
    background-color: #fff;
    position: relative;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.3);
    padding-top: 200px;
    transition: .2s all;
    border: none;
}

.cofounder .card:hover {
    background: #39742a;
    border: none;

}

.cofounder .card:hover img{
    filter: grayscale(100%);
}

.cofounder .card:hover .text{
    color: #fff;
}

.cofounder .card img {
    width: 100%;
    height: 320px;
    object-fit: cover;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
}

.cofounder .card-body {
    padding: 20px;
    margin-top: 120px;
}
