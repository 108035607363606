@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 18, 2023 */

@font-face {
  font-family: "united_sans_condblack";
  src: url("../src/fonts/unitedsanscond-black-webfont.woff2") format("woff2"),
    url("../src/fonts/unitedsanscond-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "united_sans_condbold";
  src: url("../src/fonts/unitedsanscond-bold-webfont.woff2") format("woff2"),
    url("../src/fonts/unitedsanscond-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "united_sans_condheavy";
  src: url("../src/fonts/unitedsanscond-heavy-webfont.woff2") format("woff2"),
    url("../src/fonts/unitedsanscond-heavy-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "united_sans_condlight";
  src: url("../src/fonts/unitedsanscond-light-webfont.woff2") format("woff2"),
    url("../src/fonts/unitedsanscond-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "united_sans_condmedium";
  src: url("../src/fonts/unitedsanscond-medium-webfont.woff2") format("woff2"),
    url("../src/fonts/unitedsanscond-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 5px;
  display: none;
}

::-webkit-scrollbar-track {
  background: #f5f5f5;
  display: none;

}

::-webkit-scrollbar-thumb {
  background: #ff5201;
  display: none;

}

::selection {
  color: #fff;
  background-color: #91740d;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 1200px;
}

.designimages {
  display: flex;
  flex-wrap: wrap;
  margin: 20px auto;
  width: 78%;
  width: 100%;
}

.about_content .image {
  width: 90%;
  border-radius: 10px;
  border: 1px solid;
  height: 500px;
}
.about_content .image-dropbox{
  width: 90%;
  border-radius: 10px;
  border: 1px solid;
}

.about_content .image-brand-manager{
  width: 100%;
  border-radius: 10px;
  border: 1px solid;
  height: 800px;
}
.about_content .image>img,.about_content .image-brand-manager>img{
  border-radius: 10px;
}





@media screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    background: #ada7a7;
  }
}

@media screen and (max-width: 425px) {
  .about_content .image {
    width: 100%;
    height: unset;
  }
  .about_content .image-brand-manager{
    height: unset;

  }
}
