.designupload{
  padding-bottom: 100px;
}

.designupload .upload {
  display: flex;
  justify-content: center;
  align-items: center;
}

.designupload input {
  border: 1px solid #000;
  padding: 15px;
  font-family: "Poppins", sans-serif;
}

.upload button {
  padding: 14px 10px;
  margin-left: 10px;
  background-color: #008000;
  color: #fff;
  border: none;
  font-family: "Poppins", sans-serif;
  /* font-family: "united_sans_condmedium"; */

  font-size: 22px;
}

.upload button:hover {
  background-color: #195219;
}

.designupload .upload-image {
  object-fit: cover;
  height: 500px;
  width: 940px;
  /* height: 1030px; */
  background-image: url(../../images/autoback.jpg);
  background-position: center;
  background-size: cover;
  margin: 0 auto;
  position: relative;
}

.designupload .upload-image-side {
  object-fit: cover;
  /* height: 500px;
  width: 500px; */
  height: 900px;
  width: 940px;
  background-image: url(../../images/autobackImg.jpeg);
  background-position: center;
  background-size: cover;
  margin: 20px auto;
  position: relative;

}

.banner {
  position: absolute;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  object-fit: cover;
  width: 41.1vw;
  height: 57.5vh;
  top: 35.5%;
  left: 48.6%;
  /* border: 1px solid #000; */
  transform: translate(-50%, -45%);
}

.sidebanner {
  position: absolute;
  background-position: center;
  background-size: 100% 100%;
  width: 24vw;
  height: 68vh;
  top: 36%;
  left: 65%;
  /* border: 1px solid #000; */
  transform: translate(-92%, -45%);
}

.graphic-size {
  font-size: 22px;
  font-family: 'POPPINS';

}

/* img-error-msg */
.img-error-msg {
  color: red;
  font-size: 22px;
  /* margin-left: 10px; */
  /* padding: 0 10px; */
  font-family: 'POPPINS';
}

@media screen and (max-width: 1024px) {
  .designupload .upload-image {
    padding: 0;
    width: 100%;
    max-height: 100%;
  }

  .banner {
    width: 58vw;
    height: 42vh;
    max-height: 100%;
  }

  .sidebanner {
    width: 22vw;
    height: 36vh;
    max-height: 100%;
  }
}

@media screen and (max-width: 900px) {
  .designupload .upload-image {
    padding: 0;
    width: 90%;
    max-height: 90%;
  }

  .banner {
    width: 58vw;
    height: 42vh;
    max-height: 100%;
  }

  .sidebanner {
    width: 22vw;
    height: 36vh;
    max-height: 100%;
  }


}

@media screen and (width: 393px) and (height: 851px) {


  .sidebanner {
    width: 46vw;
    height: 30vh;
    max-height: 100%;
  }
}

@media screen and (max-width: 600px) {
  .designupload input {
    border: 1px solid #000;
  }

  .designupload .upload {
    display: flex;
    flex-direction: column;
  }

  .upload button {
    margin-top: 15px;
  }

  .designupload .upload-image {
    padding: 0;
    width: 100%;
  }

  .banner {
    width: 44vh;
    height: 30vh;
  }

  .sidebanner {
    width: 50vw;
    height: 31vh;
  }
}

@media screen and (max-width: 428px) {
  .designupload input {
    border: 1px solid #000;
  }

  .designupload .upload {
    display: flex;
    flex-direction: column;
  }

  .upload button {
    margin-top: 15px;
  }

  .designupload .upload-image {
    padding: 0;
    width: 100%;
  }

  .banner {
    width: 44vh;
    height: 30vh;
  }
  .designupload .upload-image-side{
    width:unset
  }

  .sidebanner {
    left: 80%;
    top: 40%;
    width: 63vw;
    height: 41vh;
  }
}