.vertical-progress-bar {
  position: fixed;
  right: 33px;
  width: 12px;
  height: 70%;
  overflow: hidden;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
}
.progress-bar {
  background-color: #fff;
}
  
.progress-track{
  opacity: 0.5; 
  background-color: rgb(245, 245, 241);
}

@media screen and (max-width: 767px) {
    .vertical-progress-bar {
        right: 10px;
        width: 5px;
    }
}

@media screen and (max-width: 420px) {
    .vertical-progress-bar {
        right: 10px;
        width: 5px;
    }
}

@media screen and (max-width: 325px) {
    .vertical-progress-bar {
        right: 10px;
        width: 5px;
    }
}
