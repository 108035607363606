.footer {
  padding: 80px 0 15px;
  background-color: #e19404;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  z-index: -1;
}

.footercol {
  justify-content: space-around;
  width: 100%;
  display: flex;
}

.footer img {
  width: 150px;
  height: auto;
  margin-left: 20px;
}

.left_footer h4 {
  position: relative;
  padding-bottom: 1rem;
  color: #444444;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  line-height: 2rem;
  margin-left: 3px;
}

.left_footer {
  border-right: 1px solid #fff;
  padding: 40px 0 40px 20px;
  display: flex;
}

.right_footer {
  padding: 40px 0px;
  display: flex;
  justify-content: center;
}

.right_footer .footercontent h5 {
  margin-bottom: 0.5rem;
  color: #444444;
  font-family: "Poppins", sans-serif;
}

.right_footer .footercontent p,
a {
  color: #444444;
  text-decoration: none;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.footercontent .footeremail {
  /* display: flex; */
  /* justify-content: center; */
  /* flex-wrap: wrap; */
}

.right_footer .footercontent a:hover {
  color: #444444;
}

.footer p {
  margin-bottom: 0px;
}

.footer .footer-nav {
  display: flex;
  /* justify-content: center; */
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  margin: 16px 0 0;
}

.footer .footer-nav li {
  transition: 1s all;
}

.footer .footer-nav li a {
  text-decoration: none;
  padding: 10px 24px;
  font-size: 19px;
  color: #444444;
  font-family: "Poppins", sans-serif;
}

footer .footer-nav li:hover {
  scale: 1.2;
}

.footer .footer-line {
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: #fff;

}

.footer .social_link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 32px;
  margin-top: 20px;
}

.footer .footertext p {
  color: #444444;
  font-family: "Poppins", sans-serif;
}

.footer .footertext a {
  color: #444444;
  font-family: "Poppins", sans-serif;
}

.icon i {
  height: 50px;
  width: 50px;
  background: #444444;
  border-radius: 50%;
  position: relative;
  margin-right: 13px;
}

.icon i::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #fff;
  transition: all 0.5s;
}

.social_link i {
  height: 50px;
  width: 50px;
  background: #444444;
  border-radius: 50%;
  position: relative;
  margin-right: 13px;
}

.social_link i::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #fff;
  transition: all 0.5s;
}

.social_link i:hover {
  /* background-color: #91740d; */
  cursor: pointer;
}


.social_link .link ul li:hover:nth-child(1) i::before {
  /* color: #0072b1;  */
  scale: 1.2;
}

.social_link .link ul li:hover:nth-child(2) i::before {
  /* color: #00acee;  */
  scale: 1.2;
}

.social_link .link ul li:hover:nth-child(3) i::before {
  /* color: #c4302b;  */
  scale: 1.2;
}

.social_link .link ul li:hover:nth-child(4) i::before {
  /* color: rgb(255, 0, 0);  */
  scale: 1.2;
}

.social_link .link ul li:hover:nth-child(5) i::before {
  /* color: #3b5998;  */
  scale: 1.2;
}

@media screen and (max-width: 992px) {
  .left_footer {
    padding: 40px 0px;
  }

  .footer ul {
    flex-wrap: wrap;
    justify-content: flex-start;

  }

  .footer ul li a {
    padding: 20px 20px;
  }

  .footer .social_link {
    display: flex;
    flex-wrap: wrap;
  }

  .footer .footertext p {
    text-align: center;
    align-items: center;
  }
}


@media screen and (max-width: 768px) {
  .left_footer {
    display: flex;
    flex-wrap: wrap;
  }
  .right_footer{
    text-align: center;
  }

  .footer .social_link {
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 0;
  }
}



@media screen and (max-width: 425px) {
  .right_footer{
    text-align: center;
    flex-direction: column;
    padding: 10px;
    justify-content: flex-start;
    }

    .footer .footer-nav li {
      width: 85px;
      transition: all 1s;
  }
  .footer-social-link{
    padding-left: 0;
  }
  .footer-social-link li{
    width: 56px;
    transition: all 1s;

  }
}